import Vue from "vue";
import OchService from "@/services/OchService";

const moment = require("moment");

export const namespaced = true;
export const state = {
  building: null,
  benchmarks: {},
  status: "none",
  dateLoaded: "",
};
export const getters = {
  /**
   * Gets the meters^2 value for the building.
   *
   * The calculation for feet^2 to meters^2:
   * feet^2 * 0.0929 = meters^2
   *
   * @returns {Number} - The m2 value.
   */
  getM2: state => {
    try {
      return parseFloat(
        parseFloat(state.building.field_sqft * 0.0929).toFixed(2)
      );
    } catch (error) {
      return 0;
    }
  },
  getGHG: (state, getters, rootState, rootGetters) => {
    try {
      const electricity = rootGetters["electricity/getLast12MonthsTotalGHG"];
      const gas = rootGetters["gas/getLast12MonthsTotalGHG"];

      return parseFloat((electricity + gas).toFixed(2));
    } catch (error) {
      return 0;
    }
  },
  getGHGM2: (state, getters) => {
    try {
      const { getM2, getGHG } = getters;

      return parseFloat((getGHG / getM2).toFixed(2));
    } catch (error) {
      return 0;
    }
  },
  getGigajoules: (state, getters, rootState, rootGetters) => {
    try {
      const electricity =
        rootGetters["electricity/getLast12MonthsTotalGigajoule"];
      const gas = rootGetters["gas/getLast12MonthsTotalGigajoule"];

      return parseFloat((electricity + gas).toFixed(2));
    } catch (error) {
      return 0;
    }
  },
  getGigajoulesM2: (state, getters) => {
    try {
      const { getM2, getGigajoules } = getters;

      return parseFloat((getGigajoules / getM2).toFixed(2));
    } catch (error) {
      return 0;
    }
  },
  getBestGHGM2: (state, getters, rootState) => {
    try {
      const { best_benchmark_electricity, best_benchmark_gas } =
        state.benchmarks;
      const electricityGHG = rootState.electricity.conversions.ghg;
      const gasGHG = rootState.gas.conversions.ghg;

      return parseFloat(
        (
          parseFloat(best_benchmark_electricity) * electricityGHG +
          parseFloat(best_benchmark_gas) * gasGHG
        ).toFixed(2)
      );
    } catch (error) {
      return 0;
    }
  },
  getBestGHG: (state, getters) => {
    try {
      const { getBestGHGM2, getM2 } = getters;

      return parseFloat((getBestGHGM2 * getM2).toFixed(2));
    } catch (error) {
      return 0;
    }
  },
  getBestGigajoulesM2: (state, getters, rootState) => {
    try {
      const { best_benchmark_electricity, best_benchmark_gas } =
        state.benchmarks;
      const electricityGigajoule = rootState.electricity.conversions.gigajoule;
      const gasGigajoule = rootState.gas.conversions.gigajoule;

      return parseFloat(
        (
          parseFloat(best_benchmark_electricity) * electricityGigajoule +
          parseFloat(best_benchmark_gas) * gasGigajoule
        ).toFixed(2)
      );
    } catch (error) {
      return 0;
    }
  },
  getBestGigajoules: (state, getters) => {
    try {
      const { getBestGigajoulesM2, getM2 } = getters;

      return parseFloat((getBestGigajoulesM2 * getM2).toFixed(2));
    } catch (error) {
      return 0;
    }
  },
  getAverageGHGM2: (state, getters, rootState) => {
    try {
      const { average_benchmark_electricity, average_benchmark_gas } =
        state.benchmarks;
      const electricityGHG = rootState.electricity.conversions.ghg;
      const gasGHG = rootState.gas.conversions.ghg;

      return parseFloat(
        (
          parseFloat(average_benchmark_electricity) * electricityGHG +
          parseFloat(average_benchmark_gas) * gasGHG
        ).toFixed(2)
      );
    } catch (error) {
      return 0;
    }
  },
  getAverageGHG: (state, getters) => {
    try {
      const { getAverageGHGM2, getM2 } = getters;

      return parseFloat((getAverageGHGM2 * getM2).toFixed(2));
    } catch (error) {
      return 0;
    }
  },
  getAverageGigajoulesM2: (state, getters, rootState) => {
    try {
      const { average_benchmark_electricity, average_benchmark_gas } =
        state.benchmarks;
      const electricityGigajoule = rootState.electricity.conversions.gigajoule;
      const gasGigajoule = rootState.gas.conversions.gigajoule;

      return parseFloat(
        (
          parseFloat(average_benchmark_electricity) * electricityGigajoule +
          parseFloat(average_benchmark_gas) * gasGigajoule
        ).toFixed(2)
      );
    } catch (error) {
      return 0;
    }
  },
  getAverageGigajoules: (state, getters) => {
    try {
      const { getAverageGigajoulesM2, getM2 } = getters;

      return parseFloat((getAverageGigajoulesM2 * getM2).toFixed(2));
    } catch (error) {
      return 0;
    }
  },
  getCars: (state, getters) => {
    try {
      const { getGHG } = getters;

      return Math.round((getGHG / 1000) * 0.211);
    } catch {
      return 0;
    }
  },
};
export const mutations = {
  /**
   * Sets the building's information.
   *
   * @param {Object} state - The Vuex state.
   * @param {String} id    - The building's data.
   */
  SET: (state, { data, benchmarks }) => {
    Vue.set(state, "building", data);
    Vue.set(state, "benchmarks", benchmarks);
    state.dateLoaded = moment().format("YYYY-MM-DD HH:mm:ss").toString();
  },
  /**
   * Sets the status status.
   *
   * @param {Object} state  - The Vuex state.
   * @param {String} status - The status.
   */
  SET_STATUS: (state, status) => {
    state.status = status;
  },
};
export const actions = {
  fetch: async ({ commit, dispatch, state, rootState }, force = false) => {
    const dateCheck =
      state.dateLoaded !== "" &&
      moment(state.dateLoaded, "YYYY-MM-DD HH:mm:ss")
        .add(rootState.device.cache.number, rootState.device.cache.unit)
        .isAfter(moment());

    if (state.loaded === "loaded" && !force && dateCheck) return state.building;

    commit("SET_STATUS", "loading");

    try {
      const { data } = await OchService.buildingInfo(rootState.device.id);

      if (data.length !== 1) {
        throw Error("Data does not match expected resposne.");
      }

      const benchmarks = await dispatch("fetchBenchmarks", {
        type: data[0].field_heating_type.toLowerCase(),
      });

      commit("SET", { data: data[0], benchmarks });
      commit("SET_STATUS", "loaded");

      return data;
    } catch (error) {
      commit("SET_STATUS", "error");

      const notification = {
        type: "error",
        message: `There was an issue while loading building information. ${error.message}`,
      };

      dispatch("notification/add", notification, { root: true });

      throw error;
    }
  },
  fetchBenchmarks: async ({ commit, dispatch }, { type }) => {
    try {
      const { data } = await OchService.benchmarks(type);

      return JSON.parse(data);
    } catch (error) {
      commit("SET_STATUS", "error");

      const notification = {
        type: "error",
        message: `There was an issue while loading building benchmark information. ${error.message}`,
      };

      dispatch("notification/add", notification, { root: true });

      throw error;
    }
  },
};
