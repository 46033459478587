<template>
  <b-alert
    :show="!production && (manual || countDown)"
    dismissible
    fade
    :variant="variant"
    @dismissed="clear"
    class="notification d-flex align-items-center"
  >
    <p class="mb-0 ml-2">{{ message }}</p>
  </b-alert>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    /**
     * The ID of the notification.
     */
    id: {
      type: [Number, String],
      required: true,
    },
    /**
     * The notification type.
     */
    type: {
      type: String,
      required: false,
      default: "info",
    },
    /**
     * The notification message.
     */
    message: {
      type: String,
      required: true,
    },
    /**
     * The amount of time (in seconds) the notification will appear for.
     */
    timer: {
      type: [Number, String],
      required: false,
      default: 5,
      validator: value => {
        return !isNaN(value);
      },
    },
    /**
     * Force a manual dismissal.
     */
    manual: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      countDown: 0,
      typeVariantConversions: {
        error: {
          variant: "danger",
          icon: "report",
        },
        info: {
          variant: "info",
          icon: "info",
        },
        success: {
          variant: "success",
          icon: "circle_check",
        },
        warning: {
          variant: "warning",
          icon: "warning",
        },
      },
      production: process.env.NODE_ENV === "production",
    };
  },
  computed: {
    /**
     * Gets the appropriate variant for the notification.
     *
     * @returns {String}
     */
    variant() {
      if (this.typeVariantConversions[this.type]) {
        return this.typeVariantConversions[this.type].variant;
      }

      return this.typeVariantConversions["info"].variant;
    },
    /**
     * Gets the appropriate icon for the notification.
     *
     * @returns {String}
     */
    icon() {
      if (this.typeVariantConversions[this.type]) {
        return this.typeVariantConversions[this.type].icon;
      }

      return this.typeVariantConversions["info"].icon;
    },
  },
  mounted() {
    this.countDown = parseInt(this.timer);

    if (this.production) {
      // eslint-disable-next-line no-console
      console.error(`${this.message} - ${this.buildingID}`);
    }
  },
  beforeDestroy() {
    this.countDown = 0;
  },
  methods: {
    ...mapActions("notification", ["remove"]),
    /**
     * Clear the countdown and remove the notification from the state.
     */
    clear() {
      this.countDown = 0;
      setTimeout(() => {
        this.remove(this.id);
      }, 250);
    },
  },
};
</script>
