import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import store from "./store";
import i18n from "@/i18n";

Vue.use(Router);

function localeNext(routeTo, routeFrom, next) {
  const locale = routeTo.params.locale;

  if (!i18n.availableLocales.includes(locale)) {
    i18n.locale = i18n.fallbackLocale;
    return next(i18n.fallbackLocale);
  } else {
    i18n.locale = locale;
    return next();
  }
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "base",
      redirect: `/${i18n.fallbackLocale}`,
    },
    {
      path: "/:locale",
      component: () => import("@/views/Container"),
      children: [
        {
          path: "",
          name: "init",
          component: () => import("@/views/Init"),
        },
        {
          path: "poster/:index",
          name: "poster",
          component: () => import("@/views/Poster"),
          props: true,
          beforeEnter: async (routeTo, routeFrom, next) => {
            try {
              store.dispatch("poster/fetch", {});
            } catch {
              // Do nothing.
            }

            return next();
          },
        },
        {
          path: "consumption",
          name: "consumption",
          component: () => import("@/views/Consumption"),
          beforeEnter: async (routeTo, routeFrom, next) => {
            try {
              await store.dispatch("electricity/fetch");
              await store.dispatch("gas/fetch");
            } catch {
              // Do nothing.
            }

            return next();
          },
        },
        {
          path: "consumption-cost",
          name: "cost",
          component: () => import("@/views/ConsumptionCost"),
          beforeEnter: async (routeTo, routeFrom, next) => {
            try {
              await store.dispatch("electricity/fetch");
              await store.dispatch("gas/fetch");
              await store.dispatch("building/fetch", {});
            } catch {
              // Do nothing.
            }

            return next();
          },
        },
        {
          path: "impact",
          name: "impact",
          component: () => import("@/views/EnvironmentalImpact"),
          beforeEnter: async (routeTo, routeFrom, next) => {
            try {
              await store.dispatch("electricity/fetch");
              await store.dispatch("gas/fetch");
              await store.dispatch("building/fetch", {});
            } catch {
              // Do nothing.
            }

            return next();
          },
        },
        {
          path: "savings",
          name: "savings",
          component: () => import("@/views/Savings"),
          beforeEnter: async (routeTo, routeFrom, next) => {
            try {
              await store.dispatch("electricity/fetch");
              await store.dispatch("gas/fetch");
            } catch {
              // Do nothing.
            }

            return next();
          },
        },
        {
          path: "tips",
          name: "tips",
          component: () => import("@/views/SavingTips"),
        },
      ],
    },
  ],
});

router.beforeEach(async (routeTo, routeFrom, next) => {
  NProgress.start();

  store.dispatch("page/updateTitle", "");
  store.dispatch("page/updateClass", "");

  try {
    await store.dispatch("device/fetch");
  } catch {
    // Do nothing.
  }

  return localeNext(routeTo, routeFrom, next);
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
