import OchService from "@/services/OchService";
import i18n from "@/i18n";
import { initialize } from "@/mixins/stateInitializer.js";

const moment = require("moment");
const initialState = {
  data: [],
  status: "none",
  dateLoaded: null,
};

export const namespaced = true;

export const state = initialize(initialState);

export const getters = {
  /**
   * Gets all poster data in the current language.
   *
   * @returns {Object[]} - The data.
   */
  get: state => state.data[i18n.locale] || state.data[i18n.defaultLocale],
  /**
   * Gets the status of the poster data in the current language.
   *
   * @returns {String} - The status.
   */
  status: state =>
    state.status[i18n.locale] || state.status[i18n.defaultLocale],
  /**
   * Gets the date the data was last loaded in the current language.
   *
   * @returns {String} - The date string.
   */
  dateLoaded: state =>
    state.dateLoaded[i18n.locale] || state.status[i18n.defaultLocale] || "",
};

export const mutations = {
  /**
   * Sets the poster data in the current language.
   *
   * @param {Object}   state - The Vuex state.
   * @param {Object[]} data  - The poster data.
   */
  SET(state, data) {
    state.data[i18n.locale] = data;
    state.dateLoaded[i18n.locale] = moment()
      .format("YYYY-MM-DD HH:mm:ss")
      .toString();
  },
  /**
   * Sets the status in the current language.
   *
   * @param {Object} state  - The Vuex state.
   * @param {String} status - The status.
   */
  SET_STATUS(state, status) {
    state.status[i18n.locale] = status;
  },
};

export const actions = {
  /**
   * Fetches a building's poster data.
   *
   * This call will only be made if:
   *  - the data hasn't already been loaded,
   *  - the force option is used, or
   *  - the data is older than the cache limit.
   *
   * @param   {Object}  param0                 - The Vuex context object.
   * @param   {Object}  param1                 - The query params.
   * @param   {Boolean} [param1.force = false] - Forces the call to be made.
   *
   * @returns {Object[]} - The data.
   */
  fetch: async (
    { commit, dispatch, getters, rootState },
    { force = false }
  ) => {
    const dateCheck =
      getters.dateLoaded !== "" &&
      moment(getters.dateLoaded, "YYYY-MM-DD HH:mm:ss")
        .add(rootState.device.cache.number, rootState.device.cache.unit)
        .isAfter(moment());

    if (getters.status === "loaded" && !force && dateCheck) return getters.get;

    commit("SET_STATUS", "loading");

    try {
      const { data } = await OchService.poster(rootState.device.id);

      commit("SET", data);
      commit("SET_STATUS", "loaded");

      return data;
    } catch (error) {
      commit("SET_STATUS", "error");

      const notification = {
        type: "error",
        message: `There was an issue while loading the poster. ${error.message}`,
      };

      dispatch("notification/add", notification, { root: true });

      throw error;
    }
  },
};
