let nextId = 1;

export const namespaced = true;

export const state = {
  notifications: [],
};

export const mutations = {
  /**
   * Adds a notification to the state.
   *
   * @param {Object} state        - The Vuex state.
   * @param {Object} notification - The notification object.
   */
  PUSH(state, notification) {
    state.notifications.unshift({
      ...notification,
      id: nextId++,
    });
  },
  /**
   * Removes a notification from the state based on an ID.
   *
   * @param {Object} state - The Vuex state.
   * @param {Number} id    - The notification's ID.
   */
  DELETE(state, id) {
    state.notifications = state.notifications.filter(
      notification => notification.id !== id
    );
  },
};

export const actions = {
  /**
   * Adds a notification to the state.
   *
   * @param {Object} param0 - Vuex context object.
   * @param {Object} param1 - Notification options.
   */
  add({ commit }, { type, message, manual, timer }) {
    commit("PUSH", { type, message, manual, timer });
  },
  /**
   * Removes a notification from the state based on an ID.
   *
   * @param {Object}          param0 - Vuex context object.
   * @param {(Number|String)} id     - The ID of the notification.
   */
  remove({ commit }, id) {
    commit("DELETE", id);
  },
};
