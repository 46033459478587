<template>
  <div class="clock">
    {{ hour | twoDigit }}:{{ minute | twoDigit }}:{{ second | twoDigit }}
  </div>
</template>

<script>
export default {
  name: "clock",
  filters: {
    /**
     * Converts numbers to 2 digit strings.
     *
     * @param   {Number} - The number to be converted.
     *
     * @returns {String}
     */
    twoDigit(value) {
      // Make sure the value is a number.
      if (isNaN(value)) throw new Error(`${value} is not a number.`);

      // Convert the value to a string.
      value = value.toString();

      // Return the string.
      return value.length > 1 ? value : `0${value}`;
    },
  },
  data() {
    return {
      interval: null,
      hour: "",
      minute: "",
      second: "",
    };
  },
  created() {
    this.interval = setInterval(() => this.calculateTime(), 1000);
  },
  methods: {
    calculateTime() {
      const time = new Date();

      this.hour = time.getHours();
      this.minute = time.getMinutes();
      this.second = time.getSeconds();
    },
  },
};
</script>
