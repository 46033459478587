import axios from "axios";
import i18n from "@/i18n";

// Create base axios api.
const client = axios.create({
  baseURL: process.env.VUE_APP_DRUPAL_BASE_URL,
  timeout: 10000,
  headers: {
    Accept: "application/hal+json",
  },
  withCredentials: true,
});

export default {
  /**
   * Gets a poster.
   *
   * @param   {String} id - The building's ID.
   *
   * @returns {Undefined} - The Axios response.
   */
  poster: id => client.get(`/${i18n.locale}/poster_page/${id}`),
  /**
   * Gets monthly electricity data.
   *
   * @param   {String} id - The building's ID.
   *
   * @returns {Undefined} - The Axios response.
   */
  electricity: id =>
    client.get(`/energycap/monthly/${id}/electricity?_format=json`),
  /**
   * Gets monthly gas data.
   *
   * @param   {String} id - The building's ID.
   *
   * @returns {Undefined} - The Axios response.
   */
  gas: id => client.get(`/energycap/monthly/${id}/gas?_format=json`),
  /**
   * Gets building information.
   *
   * @param   {String} id - The building's ID.
   *
   * @returns {Undefined} - The Axios response.
   */
  buildingInfo: id => client.get(`/building/${id}`),
  /**
   * Gets the rate for the current resource.
   *
   * @param   {String} type - The type of resource.
   *
   * @returns {Undefined} - The Axios response.
   */
  rate: type => client.get(`/rest/och_calculation/rate/${type}?_format=json`),
  /**
   * Gets the benchmark values for the current building.
   *
   * @param   {String} type - The type of heating the building has.
   *
   * @returns {Undefined} - The Axios response.
   */
  benchmarks: type =>
    client.get(`/rest/och_calculation/benchmark/${type}?_format=json`),
};
