<template>
  <b-container fluid class="notification-container">
    <transition-group name="notification" tag="div" class="row">
      <b-col
        v-for="notification in notifications"
        :key="notification.id"
        cols="12"
        class="notification-col"
      >
        <notification
          :id="notification.id"
          :type="notification.type"
          :message="notification.message"
          :manual="notification.manual"
          :timer="notification.timer"
        />
      </b-col>
    </transition-group>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import Notification from "@/components/Notification/Notification";

export default {
  components: {
    Notification,
  },
  computed: mapState("notification", ["notifications"]),
};
</script>
