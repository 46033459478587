import i18n from "@/i18n";

export default {
  computed: {
    /**
     * Gets the current locale from i18n.
     *
     * @returns {String}
     */
    locale() {
      return i18n.locale;
    },
    /**
     * Gets the set available locales from i18n.
     *
     * @returns {String[]}
     */
    locales() {
      return i18n.availableLocales;
    },
  },
  methods: {
    /**
     * Sets the current locale in i18n.
     *
     * @param {String} langCode - The desired locale.
     */
    setLocale(langCode) {
      const locale = this.locales.includes(langCode)
        ? langCode
        : i18n.fallbackLocale;

      i18n.locale = locale;

      return this.$router
        .replace({ params: { locale: locale } })
        .catch(() => {});
    },
    /**
     * Toggles between available locales in i18n.
     */
    toggleLocale() {
      const index = this.locales.indexOf(this.locale);

      if (index >= 0 && index < this.locales.length - 1) {
        this.setLocale(this.locales[index + 1]);
      } else {
        this.setLocale(this.locales[0]);
      }
    },
  },
};
