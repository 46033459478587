import i18n from "@/i18n";
import { initialize } from "@/mixins/stateInitializer.js";

const initialState = {
  title: "",
};

export const namespaced = true;

export const state = { ...initialize(initialState), class: "" };

export const getters = {
  /**
   * Gets the title of the current page.
   *
   * @returns {String}
   */
  title: state => state.title[i18n.locale] || state.title[i18n.fallbackLocale],
};
export const mutations = {
  /**
   * Sets the title in the current language.
   *
   * @param {Object} state - The Vuex state.
   * @param {Object} value - The title.
   */
  SET_TITLE: (state, value) => {
    state.title[i18n.locale] = value;
  },
  /**
   * Sets the class.
   *
   * @param {Object} state - The Vuex state.
   * @param {Object} value - The class.
   */
  SET_CLASS: (state, value) => {
    state.class = value;
  },
};
export const actions = {
  /**
   * Updates the title.
   *
   * @param {Object} param0 - The Vuex context object.
   * @param {String} value  - The title.
   */
  updateTitle: ({ commit }, value) => {
    commit("SET_TITLE", value);
  },
  /**
   * Updates the class.
   *
   * @param {Object} param0 - The Vuex context object.
   * @param {String} value  - The class.
   */
  updateClass: ({ commit }, value) => {
    commit("SET_CLASS", value);
  },
};
