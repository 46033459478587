import OchService from "@/services/OchService";

const moment = require("moment");

function exceptionMultiplier(building) {
  switch (building) {
    case "1485 Caldwell":
      return 0.58;
    default:
      return 1;
  }
}

export const namespaced = true;

export const state = {
  data: [],
  status: "none",
  dateLoaded: "",
  conversions: {
    ghg: 1.899,
    gigajoule: 0.039,
    rate: 0.15,
  },
};

export const getters = {
  /**
   * Gets the gas data for the last month that has valid data in the current language.
   *
   * @returns {Object|null} - The data.
   */
  getLastMonth: (state, getters, rootState) => {
    try {
      const { data } = state;
      const lastMonth = moment(rootState.device.startDate, "YYYY-MM-DD");
      const currentMonth = data.find(item => {
        const month =
          item?.field_month?.data?.replace(/\s/g, "") ===
          lastMonth.format("MMM").toString();
        const year =
          item?.field_this_year_gas_use?.label?.replace(/\s/g, "") ===
          lastMonth.format("YYYY").toString();

        return month && year;
      });
      let entry = null;

      // If there isn't a meter for gas, just return the current month.
      if (rootState.building.building?.field_energ_gas_met_code === "") {
        entry = currentMonth;
      } else {
        for (let ctr = data.indexOf(currentMonth); ctr >= 0; ctr--) {
          if (data[ctr].field_this_year_gas_use?.data !== "") {
            entry = data[ctr];
            break;
          }
        }
      }

      return entry;
    } catch {
      return null;
    }
  },
  /**
   * Gets the gas data for the last month in the previous year in the current language.
   *
   * @returns {Object|null} - The data.
   */
  getLastMonthLastYear: (state, getters) => {
    const { data } = state;
    const lastMonth = getters.getLastMonth;
    const index = data.indexOf(lastMonth);

    return data[index - 12];
  },
  /**
   * Gets the monthly gas data for the last 12 months in the current language.
   *
   * @returns {Object[]} - The data.
   */
  getLast12Months: (state, getters) => {
    try {
      const { data } = state;
      const lastMonth = getters.getLastMonth;
      const index = data.indexOf(lastMonth) + 1;

      return data.slice(index - 12, index);
    } catch {
      return [];
    }
  },
  /**
   * Gets the monthly gas data for the previous 12 months in the current language.
   *
   * @returns {Object[]} - The data.
   */
  getPrevious12Months: (state, getters) => {
    try {
      const { data } = state;
      const lastMonthLastYear = getters.getLastMonthLastYear;
      const index = data.indexOf(lastMonthLastYear) + 1;

      return data.slice(index - 12, index);
    } catch {
      return [];
    }
  },
  /**
   * Gets the monthly gas data for the last calendar year in the current language.
   *
   * @returns {Object[]} - The data.
   */
  getLastYear: state => {
    try {
      const { data } = state;

      return data.slice(data.length - 24, data.length - 12);
    } catch {
      return [];
    }
  },
  /**
   * Gets the monthly gas data for this calendar year in the current language.
   *
   * @returns {Object[]} - The data.
   */
  getThisYear: state => {
    try {
      const { data } = state;

      return data.slice(data.length - 12, data.length);
    } catch {
      return [];
    }
  },
  /**
   * Gets the total gas consumption for the last calendar year in the current language.
   *
   * @returns {Number} - The consumption data.
   */
  getLastYearTotalConsumption: (state, getters) => {
    try {
      return (
        Math.round(
          getters.getLastYear
            .map(entry =>
              entry.field_this_year_gas_use?.data
                ? parseInt(entry.field_this_year_gas_use?.data)
                : 0
            )
            .reduce((total, value) => total + value)
        ) || 0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total gas consumption for this calendar year in the current language.
   *
   * @returns {Number} - The consumption data.
   */
  getThisYearTotalConsumption: (state, getters) => {
    try {
      return (
        Math.round(
          getters.getThisYear
            .map(entry =>
              entry.field_this_year_gas_use?.data
                ? parseInt(entry.field_this_year_gas_use?.data)
                : 0
            )
            .reduce((total, value) => total + value)
        ) || 0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total gas consumption for the last 12 months in the current language.
   *
   * @returns {Number} - The consumption data.
   */
  getLast12MonthsTotalConsumption: (state, getters) => {
    try {
      return (
        Math.round(
          getters.getLast12Months
            .map(entry =>
              entry.field_this_year_gas_use?.data
                ? parseInt(entry.field_this_year_gas_use?.data)
                : 0
            )
            .reduce((total, value) => total + value)
        ) || 0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total gas consumption for the previous 12 months in the current language.
   *
   * @returns {Number} - The consumption data.
   */
  getPrevious12MonthsTotalConsumption: (state, getters) => {
    try {
      return (
        Math.round(
          getters.getPrevious12Months
            .map(entry =>
              entry.field_this_year_gas_use?.data
                ? parseInt(entry.field_this_year_gas_use?.data)
                : 0
            )
            .reduce((total, value) => total + value)
        ) || 0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total GHG emissions data for the last calendar year in the current language.
   *
   * @returns {Number} - The GHG data.
   */
  getLastYearTotalGHG: (state, getters) => {
    try {
      return getters.getLastYearTotalConsumption * state.conversions.ghg || 0;
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total GHG emissions data for this calendar year in the current language.
   *
   * @returns {Number} - The GHG data.
   */
  getThisYearTotalGHG: (state, getters) => {
    try {
      return getters.getThisYearTotalConsumption * state.conversions.ghg || 0;
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total GHG emissions data for the last 12 months in the current language.
   *
   * @returns {Number} - The GHG data.
   */
  getLast12MonthsTotalGHG: (state, getters) => {
    try {
      return (
        getters.getLast12MonthsTotalConsumption * state.conversions.ghg || 0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total GHG emissions data for the previous 12 months in the current language.
   *
   * @returns {Number} - The GHG data.
   */
  getPrevious12MonthsTotalGHG: (state, getters) => {
    try {
      return (
        getters.getPrevious12MonthsTotalConsumption * state.conversions.ghg || 0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total Gigajoule emissions data for the last calendar year in the current language.
   *
   * @returns {Number} - The Gigajoule data.
   */
  getLastYearTotalGigajoule: (state, getters) => {
    try {
      return (
        getters.getLastYearTotalConsumption * state.conversions.gigajoule || 0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total Gigajoule emissions data for this calendar year in the current language.
   *
   * @returns {Number} - The Gigajoule data.
   */
  getThisYearTotalGigajoule: (state, getters) => {
    try {
      return (
        getters.getThisYearTotalConsumption * state.conversions.gigajoule || 0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total Gigajoule emissions data for last 12 months in the current language.
   *
   * @returns {Number} - The Gigajoule data.
   */
  getLast12MonthsTotalGigajoule: (state, getters) => {
    try {
      return (
        getters.getLast12MonthsTotalConsumption * state.conversions.gigajoule ||
        0
      );
    } catch {
      return 0;
    }
  },
  /**
   * Gets the total Gigajoule emissions data for previous 12 months in the current language.
   *
   * @returns {Number} - The Gigajoule data.
   */
  getPrevious12MonthsTotalGigajoule: (state, getters) => {
    try {
      return (
        getters.getPrevious12MonthsTotalConsumption *
          state.conversions.gigajoule || 0
      );
    } catch {
      return 0;
    }
  },
};

export const mutations = {
  /**
   * Sets the gas data in the current language.
   *
   * @param {Object}   state       - The Vuex state.
   * @param {Object[]} param1.data - The gas data.
   * @param {Number}   param1.rate - The rate for currency conversion.
   */
  SET(state, { data, rate }) {
    state.conversions.rate = rate;
    state.data = data;
    state.dateLoaded = moment().format("YYYY-MM-DD HH:mm:ss").toString();
  },
  /**
   * Sets the status in the current language.
   *
   * @param {Object} state  - The Vuex state.
   * @param {String} status - The status.
   */
  SET_STATUS(state, status) {
    state.status = status;
  },
};

export const actions = {
  /**
   * Fetches a building's gas data.
   *
   * This call will only be made if:
   *  - the data hasn't already been loaded,
   *  - the force option is used, or
   *  - the data is older than the cache limit.
   *
   * @param   {Object}  param0          - The Vuex context object.
   * @param   {Boolean} [force = false] - Forces the call to be made.
   *
   * @returns {Object[]} - The data.
   */
  fetch: async (
    { commit, dispatch, state, getters, rootState },
    force = false
  ) => {
    const dateCheck =
      state.dateLoaded !== "" &&
      moment(state.dateLoaded, "YYYY-MM-DD HH:mm:ss")
        .add(rootState.device.cache.number, rootState.device.cache.unit)
        .isAfter(moment());

    if (state.status === "loaded" && !force && dateCheck) {
      return state.data;
    }

    commit("SET_STATUS", "loading");

    try {
      const { data } = await OchService.gas(rootState.device.id);
      const rate = await dispatch("fetchRate");

      // Make sure values follow any exceptions defined.
      if (exceptionMultiplier(rootState.building.building.title) !== 1) {
        data.forEach((entry, index) => {
          // if (entry.field_prev_year_gas_use?.data !== "") {
          //   const value = parseFloat(entry.field_prev_year_gas_use?.data);

          //   data[index].field_prev_year_gas_use.data = (
          //     value * exceptionMultiplier(rootState.building.building.title)
          //   ).toFixed(2);
          // }
          if (entry.field_this_year_gas_use?.data !== "") {
            const value = parseFloat(entry.field_this_year_gas_use.data);

            data[index].field_this_year_gas_use.data = (
              value * exceptionMultiplier(rootState.building.building.title)
            ).toFixed(2);
          }
        });
      }

      commit("SET", {
        data: data.sort((a, b) => {
          const aYear = a.field_this_year_gas_use?.label.replace(/\s/g, "");
          const bYear = b.field_this_year_gas_use?.label.replace(/\s/g, "");

          if (aYear > bYear) return 1;
          if (aYear < bYear) return -1;

          const aMonth = parseInt(
            moment(a.field_month?.data.replace(/\s/g, ""), "MMM")
              .format("M")
              .toString()
          );
          const bMonth = parseInt(
            moment(b.field_month?.data.replace(/\s/g, ""), "MMM")
              .format("M")
              .toString()
          );

          if (aMonth > bMonth) return 1;
          if (aMonth < bMonth) return -1;

          return 0;
        }),
        rate,
      });

      const month = getters.getLastMonth?.field_month?.data;
      const year = getters.getLastMonth?.field_this_year_gas_use?.label;
      const date = moment(`${year}-${month}-01`, "YYYY-MMM-DD");
      const deviceDate = moment(rootState.device.startDate, "YYYY-MM-DD");

      if (date.isBefore(deviceDate)) {
        dispatch(
          "device/updateStartDate",
          date.format("YYYY-MM-DD").toString(),
          { root: true }
        );
      }
      commit("SET_STATUS", "loaded");

      return data;
    } catch (error) {
      commit("SET_STATUS", "error");

      const notification = {
        type: "error",
        message: `There was an issue while loading gas. ${error.message}`,
      };

      dispatch("notification/add", notification, { root: true });

      throw error;
    }
  },
  fetchRate: async ({ commit, dispatch }) => {
    try {
      const { data } = await OchService.rate("gas");
      const rate = parseFloat(JSON.parse(data).rate);

      if (!isNaN(rate)) {
        return rate;
      } else {
        return 0.15;
      }
    } catch (error) {
      commit("SET_STATUS", "error");

      const notification = {
        type: "error",
        message: `There was an issue while loading gas rate. ${error.message}`,
      };

      dispatch("notification/add", notification, {
        root: true,
      });

      throw error;
    }
  },
};
