<template>
  <div id="app" :class="`${pageClass}`">
    <header
      :class="[
        'header',
        'container-fluid',
        'bg-white',
        'border-width-5',
        'border-bottom',
        'border-success',
        'text-center',
      ]"
    >
      <b-row class="d-flex align-content-between">
        <b-col class="och-logo"></b-col>
        <transition name="fade" mode="out-in" :key="title" :duration="250">
          <b-col cols="9" class="mt-2" :key="transitionTrigger">
            <h1>{{ title }}</h1>
            <h2 class="h4">{{ $t("i18nSubHeading") }}</h2>
          </b-col>
        </transition>
        <b-col class="city-ottawa">
          <div class="ottawa-logo"></div>
          <span class="font-size-xs">{{ $t("i18nFunded") }}</span>
        </b-col>
      </b-row>
    </header>
    <notification-container />
    <b-container
      fluid
      tag="main"
      role="main"
      class="mx-0 px-0 d-flex"
      :key="transitionTrigger"
    >
      <router-view />
    </b-container>
    <b-container
      v-if="environment !== 'production'"
      class="dev-controlls"
      fluid
    >
      <b-btn
        variant="light"
        @click="showDevTools = !showDevTools"
        class="d-inline-block"
      >
        {{ showDevTools ? "Hide" : "Show" }} tools
      </b-btn>
      <div class="ml-auto position-absolute" v-show="showDevTools">
        <b-btn variant="primary" @click="toggleCycle" class="mr-4">
          Turn {{ cycleRoutes ? "Off" : "On" }} Switching
        </b-btn>
        <b-btn variant="dark" @click="previousRoute" class="mr-2">
          Previous Page
        </b-btn>
        <b-btn variant="dark" @click="nextRoute"> Next Page </b-btn>
        <b-btn variant="success" @click="toggleLocale" class="ml-4">
          Switch Language
        </b-btn>
      </div>
    </b-container>
    <footer
      :class="[
        'footer',
        'container-fluid',
        'position-absolute',
        'text-white',
        'py-1',
        'bg-secondary',
      ]"
    >
      <b-row class="d-flex align-items-center align-content-center">
        <b-col
          cols="2"
          class="d-flex justify-content-center align-items-center"
        >
          <clock class="font-size-xl w-100" />
          <img
            :src="require('@/assets/images/social-icons.svg')"
            style="height: 2rem"
          />
        </b-col>
        <b-col
          class="d-flex justify-content-center align-items-cente text-center"
        >
          <p class="m-0 p-0 font-size-lg">
            {{ $t("i18nFooter.questions") }}<br />{{ $t("i18nFooter.contact") }}
          </p>
        </b-col>
        <b-col
          cols="2"
          class="d-flex justify-content-center align-items-center"
        >
          <p class="m-0 p-0 font-size-lg text-right w-100">
            {{ $t("i18nLangFollows", { seconds: langSwitchTimer }) }}
          </p>
        </b-col>
      </b-row>
    </footer>
  </div>
</template>

<script>
import NotificationContainer from "@/components/Notification/NotificationContainer";
import Clock from "@/components/Clock/Clock";
import { mapState, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "app",
  components: {
    NotificationContainer,
    Clock,
  },
  data() {
    return {
      cycleInterval: 15,
      appRoutes: [],
      routeLocaleCount: {},
      cycleRoutes: true,
      transitionTrigger: false,
      currentRouteIndex: 0,
      langSwitchTimer: 0,
      cycleFunction: null,
      showDevTools: false,
    };
  },
  computed: {
    ...mapState({
      posterData: state => state.poster.data,
      pageClass: state => state.page.class,
    }),
    ...mapGetters("page", ["title"]),
    environment() {
      return process.env.NODE_ENV;
    },
  },
  watch: {
    locale() {
      const { routeLocaleCount, locale, cycleInterval } = this;

      this.langSwitchTimer = routeLocaleCount[locale] * cycleInterval;
      this.triggerTransition();
    },
  },
  mounted() {
    this.initialize();

    document.addEventListener("keydown", event => {
      const key = event.key || event.keyCode;
      let stopEvent = false;

      switch (key) {
        case " ":
        case "Spacebar":
        case 32:
          this.toggleCycle();

          stopEvent = true;

          break;

        case "ArrowRight":
        case "Right":
        case 39:
          this.nextRoute();

          stopEvent = true;

          break;

        case "ArrowLeft":
        case "Left":
        case 37:
          this.previousRoute();

          stopEvent = true;

          break;

        case "ArrowUp":
        case "Up":
        case 38:
        case "ArrowDown":
        case "Down":
        case 40:
          this.toggleLocale();

          stopEvent = true;

          break;

        default:
          stopEvent = false;
      }

      if (stopEvent) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  },
  methods: {
    ...mapActions({
      fetchBuilding: "building/fetch",
      fetchElectricity: "electricity/fetch",
      fetchGas: "gas/fetch",
      fetchPosters: "poster/fetch",
    }),
    nextRoute() {
      const { appRoutes, cycleInterval, $router, locale, routeLocaleCount } =
        this;

      if (this.currentRouteIndex < appRoutes.length - 1) {
        this.currentRouteIndex++;
      } else {
        this.currentRouteIndex = 0;
      }

      $router.push(appRoutes[this.currentRouteIndex]).catch(() => {});

      if (this.currentRouteIndex === 0) {
        this.langSwitchTimer = routeLocaleCount[locale] * cycleInterval;
      }
    },
    previousRoute() {
      const { appRoutes, cycleInterval, $router, locale, routeLocaleCount } =
        this;

      if (this.currentRouteIndex > 0) {
        this.currentRouteIndex--;
      } else {
        this.currentRouteIndex = appRoutes.length - 1;
      }

      $router.push(appRoutes[this.currentRouteIndex]).catch(() => {});

      if (this.currentRouteIndex === 0) {
        this.langSwitchTimer = routeLocaleCount[locale] * cycleInterval;
      }
    },
    triggerTransition() {
      this.transitionTrigger = !this.transitionTrigger;
    },
    toggleCycle() {
      this.cycleRoutes = !this.cycleRoutes;

      this.setCycleFunction();
    },
    setCycleFunction() {
      const { cycleRoutes, cycleInterval } = this;

      if (cycleRoutes) {
        this.cycleFunction = setInterval(() => {
          this.nextRoute();
        }, 1000 * cycleInterval);
      } else {
        clearInterval(this.cycleFunction);
      }
    },
    async initialize() {
      const { locales, posterData, $router, cycleRoutes } = this;

      // Load Building data.
      try {
        await this.fetchBuilding();
      } catch {
        // Do nothing. Screens will try to load again before enter.
      }

      // Load Electricity data.
      try {
        await this.fetchElectricity();
      } catch {
        // Do nothing. Screens will try to load again before enter.
      }

      // Load Gas data.
      try {
        await this.fetchGas();
      } catch {
        // Do nothing. Screens will try to load again before enter.
      }

      for (const locale of locales) {
        if (this.locale !== locale) {
          await this.setLocale(locale);
        }

        // Load data for posters.
        try {
          await this.fetchPosters({});

          for (let ctr = 1; ctr <= posterData[locale].length; ctr++) {
            this.appRoutes.push(`/${locale}/poster/${ctr}`);
          }
        } catch {
          // Do nothing. If posters don't load, we won't show them.
        }

        const staticRoutes = [
          // `/${locale}/consumption`,
          `/${locale}/consumption-cost`,
          `/${locale}/impact`,
          // `/${locale}/savings`,
          `/${locale}/tips`,
        ];
        const totalRoutes = posterData[locale].length + staticRoutes.length;

        this.appRoutes = [...this.appRoutes, ...staticRoutes];
        Vue.set(this.routeLocaleCount, locale, totalRoutes);
      }

      try {
        await this.setLocale("en");
      } catch {
        // Do nothing.
      }

      if ($router.currentRoute.path !== "/") {
        this.currentRouteIndex = this.appRoutes.indexOf(
          $router.currentRoute.path
        );
      } else {
        this.currentRouteIndex = -1;
      }

      this.setCycleFunction();

      if (this.cycleRoutes) {
        setInterval(() => (this.langSwitchTimer -= 1), 1000);
      }
    },
  },
};
</script>
