var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${_vm.pageClass}`,attrs:{"id":"app"}},[_c('header',{class:[
      'header',
      'container-fluid',
      'bg-white',
      'border-width-5',
      'border-bottom',
      'border-success',
      'text-center',
    ]},[_c('b-row',{staticClass:"d-flex align-content-between"},[_c('b-col',{staticClass:"och-logo"}),_c('transition',{key:_vm.title,attrs:{"name":"fade","mode":"out-in","duration":250}},[_c('b-col',{key:_vm.transitionTrigger,staticClass:"mt-2",attrs:{"cols":"9"}},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('h2',{staticClass:"h4"},[_vm._v(_vm._s(_vm.$t("i18nSubHeading")))])])],1),_c('b-col',{staticClass:"city-ottawa"},[_c('div',{staticClass:"ottawa-logo"}),_c('span',{staticClass:"font-size-xs"},[_vm._v(_vm._s(_vm.$t("i18nFunded")))])])],1)],1),_c('notification-container'),_c('b-container',{key:_vm.transitionTrigger,staticClass:"mx-0 px-0 d-flex",attrs:{"fluid":"","tag":"main","role":"main"}},[_c('router-view')],1),(_vm.environment !== 'production')?_c('b-container',{staticClass:"dev-controlls",attrs:{"fluid":""}},[_c('b-btn',{staticClass:"d-inline-block",attrs:{"variant":"light"},on:{"click":function($event){_vm.showDevTools = !_vm.showDevTools}}},[_vm._v(" "+_vm._s(_vm.showDevTools ? "Hide" : "Show")+" tools ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDevTools),expression:"showDevTools"}],staticClass:"ml-auto position-absolute"},[_c('b-btn',{staticClass:"mr-4",attrs:{"variant":"primary"},on:{"click":_vm.toggleCycle}},[_vm._v(" Turn "+_vm._s(_vm.cycleRoutes ? "Off" : "On")+" Switching ")]),_c('b-btn',{staticClass:"mr-2",attrs:{"variant":"dark"},on:{"click":_vm.previousRoute}},[_vm._v(" Previous Page ")]),_c('b-btn',{attrs:{"variant":"dark"},on:{"click":_vm.nextRoute}},[_vm._v(" Next Page ")]),_c('b-btn',{staticClass:"ml-4",attrs:{"variant":"success"},on:{"click":_vm.toggleLocale}},[_vm._v(" Switch Language ")])],1)],1):_vm._e(),_c('footer',{class:[
      'footer',
      'container-fluid',
      'position-absolute',
      'text-white',
      'py-1',
      'bg-secondary',
    ]},[_c('b-row',{staticClass:"d-flex align-items-center align-content-center"},[_c('b-col',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"cols":"2"}},[_c('clock',{staticClass:"font-size-xl w-100"}),_c('img',{staticStyle:{"height":"2rem"},attrs:{"src":require('@/assets/images/social-icons.svg')}})],1),_c('b-col',{staticClass:"d-flex justify-content-center align-items-cente text-center"},[_c('p',{staticClass:"m-0 p-0 font-size-lg"},[_vm._v(" "+_vm._s(_vm.$t("i18nFooter.questions"))),_c('br'),_vm._v(_vm._s(_vm.$t("i18nFooter.contact"))+" ")])]),_c('b-col',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"cols":"2"}},[_c('p',{staticClass:"m-0 p-0 font-size-lg text-right w-100"},[_vm._v(" "+_vm._s(_vm.$t("i18nLangFollows", { seconds: _vm.langSwitchTimer }))+" ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }