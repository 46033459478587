import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import App from "@/App.vue";
import router from "@/router";
import store from "./store";
import i18n from "@/i18n";
import "chartist/dist/chartist.min.css";
import "@/assets/scss/styles.scss";
import "nprogress/nprogress.css";
import localeSwitcher from "@/mixins/localeSwitcher";
import Chartist from "@/mixins/chartist";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

Vue.mixin(localeSwitcher);

new Vue({
  router,
  store,
  i18n,
  Chartist,
  render: h => h(App),
}).$mount("#app");
