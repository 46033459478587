export const namespaced = true;

const moment = require("moment");
const lastMonth = moment().subtract("1", "month");

export const state = {
  id: null,
  status: "none",
  cache: {
    number: 1,
    unit: "day",
  },
  startDate: lastMonth.format("YYYY-MM-DD").toString(),
};

export const mutations = {
  /**
   * Sets the building's ID.
   *
   * @param {Object} state - The Vuex state.
   * @param {String} id    - The building's ID.
   */
  SET: (state, id) => {
    state.id = id;
  },
  /**
   * Sets the status status.
   *
   * @param {Object} state  - The Vuex state.
   * @param {String} status - The status.
   */
  SET_STATUS: (state, status) => {
    state.status = status;
  },
  /**
   * Sets the startDate.
   *
   * @param {Object} state - The Vuex state.
   * @param {String} date  - The date.
   */
  SET_DATE: (state, date) => {
    state.startDate = date;
  },
};

export const actions = {
  /**
   * Fetches a building's ID from a Chrome device.
   *
   * This call will only be made if:
   *  - the data hasn't already been loaded, or
   *  - the force option is used.
   *
   * @param {Object}  param0          - The Vuex context object.
   * @param {Boolean} [force = false] - Forces the call to be made.
   */
  fetch: ({ commit, dispatch, state }, force = false) => {
    if (state.status === "loaded" && !force) return state.id;

    commit("SET_STATUS", "loading");

    try {
      const vars = [];
      let hash;
      let q = document.URL.split("?")[1];
      q = q.split("&");
      for (let i = 0; i < q.length; i++) {
        hash = q[i].split("=");
        vars.push(hash[1]);
        vars[hash[0]] = hash[1];
      }

      const buildingID = vars["deviceId"].substring(0, 36);

      commit("SET", buildingID);
      commit("SET_STATUS", "loaded");

      return state.id;
    } catch (error) {
      commit("SET_STATUS", "error");

      // If not prod, set the ID to the dev chromebit. Otherwise show the error.
      if (process.env.NODE_ENV !== "production") {
        commit("SET", process.env.VUE_APP_DEFAULT_BUILDING_ID);
      } else {
        const notification = {
          type: "error",
          message: `There was an error while trying to get the building's ID. ${error.message}`,
        };

        dispatch("notification/add", notification, { root: true });
      }
    }
  },
  /**
   * Updates the starting date for the application.
   *
   * @param {Object} param0 - The Vuex context object.
   * @param {String} date   - The date.
   */
  updateStartDate: ({ commit }, date) => {
    commit("SET_DATE", date);
  },
};
