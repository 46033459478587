import i18n from "@/i18n.js";
import Vue from "vue";

/**
 * Generates a Vuex state object with variables split into i18n locales.
 *
 * @param   {Object} values - An object representing the state variables and their initial values.
 *
 * @returns {Object} - The new state object.
 */
export const initialize = values => {
  // Get the available locales
  const locales = i18n.availableLocales;
  // A container object for the new state.
  const state = {};

  for (let [key, value] of Object.entries(values)) {
    // Set a basic object for each state variable.
    Vue.set(state, key, {});

    // Create a new entry for each available locale using the provided default value.
    locales.forEach(locale => Vue.set(state[key], locale, value));
  }

  return state;
};
