import Vue from "vue";
import Vuex from "vuex";
import * as notification from "./modules/notification";
import * as poster from "./modules/poster";
import * as device from "./modules/device";
import * as electricity from "./modules/electricity";
import * as gas from "./modules/gas";
import * as page from "./modules/page";
import * as building from "./modules/building";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notification,
    poster,
    device,
    electricity,
    gas,
    page,
    building,
  },
});
